import React, {useEffect} from 'react';
import "../../stylesheets/landing.css"
import WayfairLogo from "../../images/w-logo.svg";
import NayaLogo from "../../images/n-logo.svg";
import { withRouter } from 'react-router-dom';

const SignedUp = (props) => {
    useEffect(() => {
        const timer = setTimeout(() => {
            props.history.push('/account');
        }, 5000);
        return () => clearTimeout(timer);
    }, []);
    return (
        <>
            <div id="landing">
                <div className="landing-body">
                    <h1 style={{ fontFamily: "sofia-pro", fontWeight: 100 }}>Thank you for signing up!</h1>
                    <p style={{ color: "gray" }} >We will now redirect you to your account</p>
                    <div style={{ marginBottom: 40, fontSize: 60 }} >
                        <img className="landingimg" src={WayfairLogo} alt="Wayfair" />{window.innerWidth < 575 && <br />}+{window.innerWidth < 575 && <br />}<img className="landingimg" src={NayaLogo} alt="Naya Studio" />
                    </div>
                </div>

            </div>

        </>
    )
}

export default withRouter(SignedUp);