import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import "../../stylesheets/login.css"
import "../../stylesheets/landing.css"
import { signUpInGoogle } from "../../actions/authActions"

import Logo from "../../images/logonaya.jpg"
import WayfairLogo from "../../images/w-logo.svg";
import NayaLogo from "../../images/n-logo.svg";

class Login extends Component {
    onClickHandler = () => {
        if (localStorage.getItem("nayaJwtToken") !== null) {
            return (window.location.href = "https://app.naya.studio");
        } else {
            this.props.history.push("/login");
        }
    }
    render() {
        return (
            <>
                <div style={{ backgroundColor: "white", zIndex: 10, height: "3rem" }}>

                    <div className="landing-login" onClick={() => this.props.history.push('/login')} >Login/Signup</div>
                    <div className="landing-naya" onClick={() => window.location.href = "https://www.naya.studio"} >naya</div>
                </div>
                <div id="landing">
                    <div className="landing-body">
                        <h1 style={{ fontFamily: "sofia-pro", fontWeight: 100 }}>Hi Wayfair!</h1>
                        <div style={{ marginBottom: 40, fontSize: 60 }} >
                            <img className="landingimg" src={WayfairLogo} alt="Wayfair" />{window.innerWidth < 575 && <br />}+{window.innerWidth < 575 && <br />}<img className="landingimg" src={NayaLogo} alt="Naya Studio" />
                        </div>
                        <button className="btn" onClick={() => this.onClickHandler()}>Get Started</button>
                    </div>
                </div>
            </>
        )


    }
}

const mapStateToProps = (state) => {

    return {
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        // loginToken: () => dispatch(loginToken()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Login))