import React, { Component } from 'react'
import { connect } from 'react-redux'   
import { withRouter } from 'react-router-dom'
import {Row, Col, Image, Modal, Button} from 'react-bootstrap'
import CanvasDraw from "react-canvas-draw";
import axios from 'axios'
import {API_ENDPOINT, SHARING_API, SHARING_PORTAL_FRONTEND} from '../../constants/secrets'
import userIcon from '../../images/icons/userIcon.png'
import maximize from '../../images/icons/maximize.svg'
import download from '../../images/icons/download.svg'
import rotateIcon from '../../images/icons/rotateIcon.svg';
// import deleteIcon from '../../images/icons/delete.png';
import ReactQuill from "react-quill"; // ES6
import "react-quill/dist/quill.snow.css"; // ES6
import "../../stylesheets/comments.css"

import ImageBox from './ImageBox';
import {editComment} from '../../actions/commentActions';
const config = {
    headers: {
        "Access-Control-Allow-Origin": "*"    }
  }
  axios.defaults.headers.post['Content-Type'] ='application/json;charset=utf-8';
  axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
  
class CommentTile extends Component{
    constructor(props)
    {
        super(props)

        let name = "Anonymous"
        let profile_pic = userIcon
        if(this.props.data.user && this.props.data.user.email)
        {
            name = this.props.data.user.email.split("@")[0]
        }
        if(this.props.data.user && this.props.data.user.name)
        {
            name = this.props.data.user.name
        }
        if(this.props.data.user && this.props.data.user.profile_pic)
        {
            profile_pic = this.props.data.user.profile_pic
        }
        this.state = {
            cols: 1,
            showTextInput: false,
            nImgs: 0,
            imgs: [],
            inputText: '',
            profile_pic: profile_pic,
            imgLoaded: false,
            name: name,
            showLightbox: false,
            lightboxImage: null,
            rotation:0, 
            imageBoxWidth:'',
            imageBoxHeight:'auto',
            edit:false,
            comment_image_uploads:this.props.data.comment_image_uploads,
            comment_text:this.props.data.comment_text,
            admin_approved: this.props.data.admin_approved


        }
        // FOR REPLYING TO COMMENTS
        this.replyToData = {
            text: undefined,
            image: undefined
        }

        this.commentImage = React.createRef()
        this.imgContainer = React.createRef()

    }

    componentDidMount()
    {
            const GET_PROFILE_PIC_URL = `${API_ENDPOINT}/api/userprefs`
            const GET_PROFILE_NAME_URL = `${API_ENDPOINT}/api/userprefs`

            if(this.props.data.user)
            {
            let data = {
                user_id: this.props.data.user._id,
                key: "PROFILE_PIC"
            }
            axios.get(GET_PROFILE_PIC_URL, {params: data}, {crossDomain: true})
                .then(response => {
                    if(response.data.status)
                    {
                        this.setState({profile_pic: response.data.data.options[0].value})
                    }
                })
                .catch(e => {}
                )
            data = {
                user_id: this.props.data.user._id,
                key: "PROFILE_NAME"
            }
            axios.get(GET_PROFILE_NAME_URL, {params: data}, {crossDomain: true})
                .then(response => {
                    if(response.data.status)
                    {
                        this.setState({name: response.data.data.options[0].value})
                    }
                })
                .catch(e => {}
                )
            }

            this.replyToData["text"] = this.props.data.comment_text !== undefined && this.props.data.comment_text
            if(this.props.data.comment_image_uploads !== undefined && this.props.data.comment_image_uploads.length >0)
            {
                this.replyToData["image"] = this.props.data.comment_image_uploads[0].link
                this.replyToData["type"] = "IMAGE"
            }
            else{
                this.replyToData["text"] = this.props.data.comment_text !== undefined && this.props.data.comment_text
                this.replyToData["type"] = "COMMENT"
            }
    }

    timeDifference(current, previous) {

        var msPerMinute = 60 * 1000;
        var msPerHour = msPerMinute * 60;
        var msPerDay = msPerHour * 24;
        var msPerMonth = msPerDay * 30;
        var msPerYear = msPerDay * 365;
    
        var elapsed = current - previous;
    
        if (elapsed < msPerMinute) {
             return Math.round(elapsed/1000) + ' s ago';   
        }
    
        else if (elapsed < msPerHour) {
            if(Math.round(elapsed/msPerMinute) !== 1)
            {
                return Math.round(elapsed/msPerMinute) + ' mins ago';   
            }
            else
            {
                return Math.round(elapsed/msPerMinute) + ' min ago';   
            }
        }
    
        else if (elapsed < msPerDay ) {
             return Math.round(elapsed/msPerHour ) + ' h ago';   
        }
    
        else if (elapsed < msPerMonth) {
            return Math.round(elapsed/msPerDay) + ' d ago';   
        }
    
        else if (elapsed < msPerYear) {
            return  Math.round(elapsed/msPerMonth) + ' m ago';   
        }
    
        else {
            return Math.round(elapsed/msPerYear ) + ' y ago';   
        }
    }

    renderUserProfile = () =>
    {
        let render
        try {
            let name = this.state.name
            let profile_pic = this.state.profile_pic
            let date = new Date(this.props.data.updatedAt)
            let now = new Date()
            let diff = this.timeDifference(now, date)

            render = <Col xs={12} className="user-profile">
                <Row>
                    <Image src={profile_pic}></Image>
                    <Col xs={12}>
                        <p className="name">{name}</p>
                        {/* <p className="time">{this.props.data.updatedAt!==this.props.data.createdAt && "(Edited)"}</p> */}
                        <p className="time">{diff} {this.props.data.updatedAt!==this.props.data.createdAt && "(Edited)"}</p>                    
                    </Col>
                    
                </Row>
            </Col>

        } catch (error) {
            console.log(error)
            render = <p>{this.props.data.user.email}</p>
        }
        return render
    }

    getCols = () =>
    {
        let cols = this.getTextCols() + this.getImageCols()

        if(cols <4)
        {
            return cols
        }
        else
        {
            return 4
        }
    }

    getTextCols = () =>
    {
        let cols = 0
        if(this.state.comment_text !== undefined && this.state.comment_text !== '')
        {
            cols = cols + 1
            cols += Math.round(this.state.comment_text.length / 200)
        }

        if(cols > 4)
        {
            return 4
        }
        return cols
    }

    getImageCols = () =>
    {
        let cols = 0
        if(this.state.comment_image_uploads !== undefined && this.state.comment_image_uploads.length >0)
        {
            cols = cols + this.state.comment_image_uploads.length
        }

        if(this.props.data.data_linked !== undefined)
        {
            cols = cols + 1
        }

        if(this.props.data.annotation_linked !== undefined)
        {
            cols = cols + 1
        }
        return cols
    }


    rotateImage = () => {
        let newRotation = this.state.rotation + 90;
        if(newRotation === 360){
            newRotation = 0;
        }
        this.setState({
            rotation: newRotation, imageBoxHeight:'auto', imageBoxWidth:''
        })
    }

    lightBoxImageDisplay = () => {
        if(this.state.rotation===90 || this.state.rotation === 270)
        this.setState({imageBoxWidth:`${this.imgBox.current.clientWidth}px`, imageBoxHeight:`${this.imgBox.current.clientHeight}px`})
    }

    renderImageBox = (upload) =>
    {
        const {rotation, imageBoxWidth, imageBoxHeight} = this.state;
        return <Col xs={12} md={this.getCols() < 2? 12/this.getCols(): 6} 
                    lg={this.getCols() < 4? 12/this.getCols(): 4} 
                    xl={12/this.getCols()} 
                    style={{"minHeight": "300px", "padding":"0"}} className="img-wrapper">
            <Image src={upload.link} style={{transform: `rotate(${rotation}deg)`}}></Image>
            <Image src={maximize} className="maximizeButton" style={{"width":"2rem", "height":"2rem", "bottom":"1rem",  "right":"1rem", "top":"unset", "left":"unset", "cursor":"pointer"}} onClick={() => this.setState({showLightbox: true, lightboxImage: upload.link})}></Image>
            <a href={upload.link}><Image src={download} className="maximizeButton" style={{"width":"2rem", "height":"2rem", "bottom":"1rem",  "right":"3.5rem", "top":"unset", "left":"unset", "cursor":"pointer"}}></Image></a>
            <Image 
                src={rotateIcon} className="maximizeButton" 
                style={{"width":"2rem", "height":"2rem", "bottom":"1rem",  "right":"6rem", "top":"unset", "left":"unset", "cursor":"pointer"}} 
                onClick={this.rotateImage}
            ></Image>
            <Modal
                size="xl"
                show={this.state.showLightbox && this.state.lightboxImage}
                onHide={() => this.setState({showLightbox: false, lightboxImage: null})}
                aria-labelledby="example-modal-sizes-title-xl"
                id="instructions-modal"
            >
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body 
                    style={{overflowY:'auto'}}
                >
                    <Row 
                        className="justify-content-center" 
                        style={{height:imageBoxWidth}}
                        >
                        <img 
                            ref={this.imgBox}
                            src={this.state.lightboxImage} 
                            onLoad={this.lightBoxImageDisplay}
                            style={{"maxWidth":"100%", "height":imageBoxHeight, transform: `rotate(${rotation}deg)`}} alt="lightbox"></img>
                    </Row>
                </Modal.Body>
            </Modal>
        </Col>
    }

    setEdit = (e) => {
        e.preventDefault();
        this.setState({edit:true})
    }
    handleReplyTo = (e) =>
    {
        e.preventDefault()
        this.props.replyToFunction({...this.replyToData, refId: this.props.refId})
    }


    renderReplyTo = () =>
    {
        let cols = this.getCols()

        let render = <div></div>
        try{
            let replyTo = this.props.data.data_linked
            if(this.props.data.annotation_linked && this.props.data.annotation_linked._id)
            {
                replyTo = {type: "ANNOTATION"}
            }
            switch(replyTo.type)
            {
                case "SKETCH":
                        render = 
                        
                        <Col xs={12} md={this.getCols() < 2? 12/this.getCols(): 6} lg={cols < 4? 12/cols: 4} xl={12/cols} className={"reply-to"}>
                            <h6>Replying to:</h6>
                            <Col xs={12} className="canvas-tile">
                                <Row>

                                <Col xs={12} ref={ (divElement) => this.divElement = divElement} style={{"minHeight":"100%"}}>
                                    <CanvasDraw className="canvas-sketch" 
                                                disabled 
                                                canvasWidth={this.state.clientWidth}
                                                canvasHeight={this.state.clientWidth} 
                                                ref={canvasDraw => (this.loadableCanvas = canvasDraw)} 
                                                saveData={replyTo.image} />
                                    </Col>
                                </Row>
                            </Col>
                        </Col>
                    break;
                case "IMAGE":
                        render = 
                        <Col xs={12} md={this.getCols() < 2? 12/this.getCols(): 6} lg={cols < 4? 12/cols: 4} xl={12/cols} className={"reply-to"}>
                            <h6>Replying to:</h6>
                            <Col xs={12} className="canvas-tile">
                                <Row>
                                    <Image src={replyTo.image}></Image>
                                </Row>
                            </Col>
                        </Col>
                    break;
                case "PINTEREST":
                    render = 
                    <Col xs={12} md={this.getCols() < 2? 12/this.getCols(): 6} lg={cols < 4? 12/cols: 4} xl={12/cols} className={"reply-to"}>
                        <h6>Replying to:</h6>
                        <Col xs={12} className="canvas-tile">
                            <div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'100%'}}>
                                <a href={this.props.pinterest}>
                                    <i className='fa fa-pinterest fa-3x' aria-hidden='true' style={{color: "#c8232c",marginRight: 10,fontSize:'10rem'}}></i>
                                </a>
                            </div>
                        </Col>
                    </Col>
                    break;
                case "TEXT_IMG":
                        render = 
                        <Col xs={12} md={this.getCols() < 2? 12/this.getCols(): 6} lg={cols < 4? 12/cols: 4} xl={12/cols} className={"reply-to"}>
                            <h6>Replying to:</h6>
                            <Col xs={12} className="canvas-tile">
                                <Row>
                                    <Col xs={12}>
                                        <Col xs={12}>
                                            <p style={{"textAlign":"center", fontFamily:'orpheuspro, serif', 'fontSize':'1rem'}}>
                                                <div dangerouslySetInnerHTML={{__html:replyTo.text}}></div>
                                            </p>
                                        </Col>
                                        <Col xs={12} style={{"display":"flex"}}>
                                            <Image src={replyTo.image}></Image>
                                        </Col>
                                    </Col>
                                </Row>
                            </Col>
                        </Col>
                    break;
                case "TEXT":
                        render = 
                        <Col xs={12} md={this.getCols() < 2? 12/this.getCols(): 6} lg={cols < 4? 12/cols: 4} xl={12/cols} className={"reply-to"}>
                            <h6>Replying to:</h6>
                            <Col xs={12}>
                                <Row>
                                    <Col xs={12}>
                                    <p style={{"textAlign":"center", fontFamily:'orpheuspro, serif'}}>
                                        <div dangerouslySetInnerHTML={{__html:replyTo.text}}></div>
                                    </p>
                                    </Col>
                                </Row>
                            </Col>
                        </Col>
                    break;
                case "COMMENT":
                        render = 
                        <Col xs={12} md={this.getCols() < 2? 12/this.getCols(): 6} lg={cols < 4? 12/cols: 4} xl={12/cols} className={"reply-to"}>
                            <h6>Replying to:</h6>
                            <Col xs={12}>
                                <Row>
                                    <Col xs={12}>
                                    <p style={{"textAlign":"center", fontFamily:'sofia-pro',wordBreak:'break-word'}}>
                                        <div className="line-clamp" dangerouslySetInnerHTML={{__html:replyTo.text}}></div>
                                    </p>
                                    </Col>
                                </Row>
                            </Col>
                        </Col>
                    break;
                case "ANNOTATION":
                        render = <Col xs={12} md={this.getCols() < 2? 12/this.getCols(): 6} lg={cols < 4? 12/cols: 4} xl={12/cols} className={"reply-to"}>
                            <h6>Replying to:</h6>
                            <Col xs={12} className="canvas-tile"  style={{"height":this.state.clientHeight, "minHeight":"0%", "marginBottom":"1rem"}}>
                                <Row  style={{"height":this.state.clientHeight}}>

                                <Col xs={12} ref={ (divElement) => this.divElement = divElement} style={{"height":this.state.clientHeight}}>
                                    <img src={this.props.data.annotation_linked.annotationSrc} 
                                        ref={this.imgContainer} 
                                        onLoad={this.handleImageLoaded}
                                        style={{"maxWidth":"100%"}}
                                        alt="annotation"></img>

                                    {this.state.imgLoaded && this.props.data.annotation_linked.annotationSrc && 
                                    <CanvasDraw className="canvas-sketch"
                                                // imgSrc={this.props.data.annotation_linked.annotationSrc} 
                                                disabled ={true}
                                                immediateLoading = {true}
                                                canvasWidth={this.state.clientWidth}
                                                canvasHeight={this.state.clientHeight} 
                                                ref={canvasDraw => (this.loadableCanvas = canvasDraw)} 
                                                backgroundColor={"transparent"}
                                                hideGrid={true}
                                                saveData={this.props.data.annotation_linked.annotationData} />}
                                    </Col>
                                </Row>
                            </Col>
                        </Col>
                        break
                default:
                    render = <div></div>
            }
            
        }
        catch (error) {
            render = <div></div>
        }

        return render
    }

    handleImageLoaded = (e) =>
    {
        let height = this.imgContainer.current.clientHeight * this.divElement.clientWidth/this.imgContainer.current.clientWidth
        this.setState({imgLoaded: true, clientWidth: this.divElement.clientWidth, clientHeight: height})    
    }

    handleTextChange = (e) => {
        this.setState({ comment_text: e });
    };

    removeImage = (id) => {
        let filteredArray = this.state.comment_image_uploads.filter(img => img._id !== id)
        this.setState({comment_image_uploads: filteredArray});
    }

    submitEdit = (e) => {
        e.preventDefault();
        const data = {
            canvas_id: this.props.canvas._id,
            user: this.props.user.id,
            comment_text: this.state.comment_text,
            comment_image_uploads: this.state.comment_image_uploads.map((upload) => upload._id),
            id:this.props.data._id
        }
        if (this.props.data.data_linked!==undefined) {
            data["data_linked"] = this.props.data.data_linked;
        }
        this.props.editComment(data);
        this.setState({edit:false})
    }

    approveComment = (e) => {
        e.preventDefault();
        const data = {
            admin_approved: true,
            id:this.props.data._id
        }
        this.props.editComment(data);
    }

    cancelEdit = (e) => {
        e.preventDefault();
        this.setState({
            comment_text:this.props.data.comment_text,
            comment_image_uploads:this.props.data.comment_image_uploads,
            edit:false,
        })
    }


    handleShare = async(e) =>{
        e.preventDefault();
        let share_content = [];
        if(this.state.comment_text !== undefined || this.state.comment_text.trim() !== ""){
            share_content.push({"type":"TEXT", "data":this.state.comment_text, "updatedAt": new Date().toISOString()});
        }
        if(this.state.comment_image_uploads !== undefined && this.state.comment_image_uploads.length> 0){
            for(let i=0;i<this.state.comment_image_uploads.length;i++){
                share_content.push({"type":"IMAGE", "link":this.state.comment_image_uploads[i].link, "updatedAt": new Date().toISOString()});
            }
        }
        let data = {
            content:share_content,
            shared_by:this.props.user.email,
            shared_with:[],
            comments:[],
            canvas_id:this.props.canvas.id,
        }
        const SHARE_CONTENT=`${SHARING_API}/share`;
        let response = await axios.post(SHARE_CONTENT, data, config);
        if(response.status){
            window.open(`${SHARING_PORTAL_FRONTEND}/${response.data.data._id}`, "_blank", "");
            // window.location.href=`${SHARING_PORTAL_FRONTEND}/${response.data.data._id}`
        }
    }

    render()
    {
        let cols = this.getCols()
        if(cols > 0)
        {
            let replyTo = this.props.data.data_linked
            if(this.props.data.data_linked === undefined && this.props.data.annotation_linked === undefined)
            {
                replyTo = <div></div>
            }
            else
            {
                replyTo = this.renderReplyTo()
            }
            return (
            
                <Col xs={12} 
                        md={cols*6} 
                        lg={cols*3} 
                        xl={cols*3}  
                        data={cols}
                        style={{"padding":"0.5rem"}} className="comment-container">
                    <Col xs={12}>
                        {this.renderUserProfile()}   
                    </Col>

                    <Col xs={12} className="comment-tile comment-tile-render">
                        <Row>
                            {replyTo}
                            { this.state.comment_text !== undefined && this.state.comment_text.length>3 &&
                            <Col  xs={12} 
                                    md={this.getTextColsDisplay()} 
                                    lg={this.getTextColsDisplay()} 
                                    xl={this.getTextColsDisplay()} 
                                    data={this.getTextColsDisplay()}
                                    style={{"minHeight": "300px", "padding": "0"}}>
                                <div>
                                <ReactQuill
                                    value={this.state.comment_text}
                                    style={{
                                        height: "100%",
                                        fontSize: "1rem",
                                        border: "none",
                                        outline: "none",
                                    }}
                                    className={!this.state.edit ? "quill-nonedit" : "quill-edit"}
                                    onChange={this.handleTextChange}
                                />
                                </div>            
                            </Col>}
                            {this.state.comment_image_uploads !== undefined && this.state.comment_image_uploads.length> 0 && this.state.comment_image_uploads.map(img => <ImageBox key={img._id} upload={img} getCols={this.getCols} edit={this.state.edit} removeImage={this.removeImage}/>)}
                        </Row>
                    </Col>
                    
                    {!this.props.data.admin_approved && this.props.user && "email" in this.props.user && (this.props.user.email === "hvvivek2007@gmail.com" || this.props.user.email === "saadrajan@gmail.com"|| this.props.user.email === "saad@naya.studio")? <Col xs={12} style={{display:'flex', justifyContent:'space-between'}} className="approveCommentNotification">
                        <Row>
                            <Col xs={7} lg={12}>
                                <p>This comment does not notify the client.</p>
                            </Col>
                            {this.props.user && "email" in this.props.user && (this.props.user.email === "hvvivek2007@gmail.com" || this.props.user.email === "saadrajan@gmail.com"|| this.props.user.email === "saad@naya.studio") &&
                            <Col xs={5} lg={12}>
                                <Button className={"approve-btn"} onClick={this.approveComment}>Push to Client</Button>
                            </Col>}
                        </Row>
                    </Col>: null}
                    <Col className="comment-features">
                        
                        {
                            this.state.edit ? (
                                <div style={{display:'flex', flexDirection:'row', justifyContent:'flex-end'}}>
                                <a href="" className="" onClick={this.submitEdit} style={{paddingRight:10}}>Submit</a>
                                <a href="" className="" onClick={this.cancelEdit} style={{color:'#a3a3a3'}}>Cancel</a>
                                </div>
                            ) : (
                               <>
                               {
                                   this.props.data.user._id===this.props.user.id && (
                                       <>
                                        <a href="" className="edit-comment" onClick={this.setEdit} style={{paddingRight:10}}>edit</a>
                                        </>
                                   )
                               }
                               <a href="" className="edit-comment" onClick={this.handleReplyTo}>reply</a>
                               {
                                //    ['SKETCH', 'MCF', 'WHITE_MODEL'].includes(this.props.data.tag) && (
                                        <a href="" className="share-comment" onClick={this.handleShare}>Share</a>
                                //    )
                               }
                               
                               </> 
                            )
                        }
                    </Col>
                </Col>
                )
        }
        else
        {
            return null
        }
    }

    getTextColsDisplay = () =>
    {
        let totalCols = this.getCols()
        let textCols = this.getTextCols()
        if(totalCols === textCols)
        {
            return 12
        }

        // 2, 1 = 6
        // 3, 2 = 8
        // 4, 3 = 9
        if(totalCols - textCols === 1)
        {
            if(textCols === 1)
            {
                return 6
            }
            if(textCols === 2)
            {
                return 8
            }
            if(textCols === 3)
            {
                return 9
            }
        }

        // 3, 1 = 4
        // 4, 2 = 6
        if(totalCols - textCols === 2)
        {
            if(textCols === 1)
            {
                return 4
            }
            if(textCols === 2)
            {
                return 6
            }
        }

        // 4, 1 = 3
        if(totalCols - textCols === 3)
        {
            if(textCols === 1)
            {
                return 3
            }
        }
    }

    getTextColsLG = () =>
    {
        let totalCols = this.getCols()
        let textCols = this.getTextCols()
        if(totalCols === textCols)
        {
            return 12
        }

        // 2, 1 = 6
        // 3, 2 = 8
        // 4, 3 = 9
        if(totalCols - textCols === 1)
        {
            if(textCols === 1)
            {
                return 6
            }
            if(textCols === 2)
            {
                return 8
            }
            if(textCols === 3)
            {
                return 9
            }
        }

        // 3, 1 = 4
        // 4, 2 = 6
        if(totalCols - textCols === 1)
        {
            if(textCols === 1)
            {
                return 4
            }
            if(textCols === 2)
            {
                return 6
            }
        }

        // 4, 1 = 3
        if(totalCols - textCols === 1)
        {
            if(textCols === 1)
            {
                return 3
            }
        }
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
        canvas: state.canvas,
    };
};

const mapDispatchToProps = (dispatch) => {
  
    return { 
        editComment: (data) => dispatch(editComment(data)), 
    }
  }

export default connect(mapStateToProps,mapDispatchToProps)(withRouter(CommentTile))
